// import '../_helpers/wdyr';

import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';

import '@/styles/style.global.scss';
import '../_helpers/auth-header';

import { CacheProvider } from '@emotion/react';
import Head from 'next/head';
import { useCallback, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import ThemeConfig from 'theme';

import createEmotionCache from 'theme/createEmotionCache';
import { LoadingProgressProvider } from '@/hooks/useLoadingProgress';
import DeviceManager from '../components/util/deviceManager';

if (process.env.REACT_APP_DOMAIN === 'https://api.treedis.com') {
  const initSentry = async () => {
    const init = await import('@sentry/react').then((res) => res.init);
    const { Integrations } = await import('@sentry/tracing');

    init({
      dsn: 'https://501296fbbe2e4b9eb54698408a65d388@o426542.ingest.sentry.io/5541211',
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.1,
    });
  };
  initSentry();
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export default function MyApp(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const [theme, setTheme] = useState<any>({});

  const updateTheme = useCallback(() => {
    setTheme({
      device: {
        mobile: DeviceManager.isMobile(),
        landscape: DeviceManager.isHorizontalMobileView(),
        ios: DeviceManager.isIOS(),
        tablet: DeviceManager.isTablet(),
      },
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateTheme);

    updateTheme();

    return () => {
      window.removeEventListener('resize', updateTheme);
    };
  }, [updateTheme]);

  return (
    <div id="page-root">
      <CacheProvider value={emotionCache}>
        {/* eslint-disable-next-line react/no-children-prop */}
        <Head children={null} />
        {/* MUI Theme */}
        <ThemeConfig>
          {/* styled-components theme */}
          <ThemeProvider theme={theme}>
            <RecoilRoot>
              <LoadingProgressProvider>
                <Component {...pageProps} />
              </LoadingProgressProvider>
            </RecoilRoot>
          </ThemeProvider>
        </ThemeConfig>
        <ToastContainer position="bottom-center" autoClose={5000} newestOnTop closeOnClick rtl={false} pauseOnHover />
      </CacheProvider>
    </div>
  );
}
